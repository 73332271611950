import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import * as T from './types';
import { DomainSelectorPagePropsType } from '../components/DomainSelectorPage/types';
import DomainSelectorPage from '../components/DomainSelectorPage';
import { useRootContext } from '../contexts/Root';
import Renderer from '../components/Renderer';
import { AppFC } from '../types/customReactTypes';

const App: AppFC<T.RootProps> = (props) => {
  const { orgSelector, navigation } = useRootContext();

  useEffect(() => {
    if (orgSelector) {
      orgSelector.ReactDomainSelectorPage = function ReactDomainSelectorPage(
        props: Omit<DomainSelectorPagePropsType, 'orgSelector'>
      ) {
        return <DomainSelectorPage {...{ ...props, orgSelector }} />;
      };
    }
  }, [orgSelector]);

  return (
    <Router history={navigation}>
      <Renderer {...props} />
    </Router>
  );
};

export default App;
