import styled from 'styled-components';

const loadingHeight = '24px';

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  top: calc(50% - ${loadingHeight} / 2);
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
`;
