import React from 'react';
import { ShellProps } from '../src/types/shell';
import { Root as RootShellNav } from '../src';
import projectNames from '../src/configs/projectNames';
import ToastProvider from '@veneer/core/dist/scripts/toast_container/toast_provider';
import RootProvider from '../src/contexts/Root/RootProvider';
import ThemeProvider from '@veneer/theme/dist/theme_provider';

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

/**
 *
 * @param props
 */
export default function Root(props) {
  const { v1 } = window.Shell as ShellProps;
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ThemeProvider {...themeProviderProps}>
        <RootProvider
          id={projectNames.packageJsonName}
          {...{ ...props, ...v1 }}
        >
          <ToastProvider>
            <RootShellNav {...{ ...props, ...v1 }} />
          </ToastProvider>
        </RootProvider>
      </ThemeProvider>
    </section>
  );
}
