import { useState, useEffect } from 'react';
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast';
import * as T from '../types';

function useAssetImport({
  assetReference,
  component,
  assetImportCallback,
  ...props
}: T.LazyMfeLoaderPropsType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mfe, setMfe] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { singleSpa, mountParcel, ...rest } = props;
  const componentProps = {
    ...rest,
    useToast
  };

  useEffect(() => {
    if (assetReference || assetImportCallback) {
      setIsLoading(true);
      const importFunction = async () => {
        if (assetReference) {
          return System.import(assetReference);
        } else if (assetImportCallback) {
          return assetImportCallback?.();
        }
      };

      importFunction()
        .then((response) => {
          setMfe(response);
        })
        .catch(() => {
          setMfe(undefined);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setMfe(undefined);
      setIsLoading(false);
    }
  }, [assetReference, assetImportCallback]);

  useEffect(() => {
    if (!mfe?.default && !isLoading && component) {
      setMfe({ default: component });
    }
  }, [component, isLoading, mfe]);

  return {
    mfe,
    isLoading,
    componentProps
  };
}

export default useAssetImport;
