import React, { useCallback, useMemo, useEffect } from 'react';
import * as T from './types';
import { useRootContext } from '../../contexts/Root';
import Modal from '@veneer/core/dist/scripts/modal';
import LazyMfeLoader from '../LazyMfeLoader';
import { AppFC } from '../../types/customReactTypes';
import { LazyMfeLoaderPropsType } from '../LazyMfeLoader/types';
import { MicrofrontendRouterAssetType } from '../../types/shell';
import { TenantPageLoaderPropsType } from '../TenantPageLoader';

const Renderer: AppFC<T.RootProps> = (props) => {
  const {
    microfrontendRouter,
    localizationResources,
    stack,
    store,
    applicationStatusManager
  } = useRootContext();
  const { content, layout, modalContent } =
    microfrontendRouter.useReactHook(React);
  const userOnboardingPath =
    store?.state?.manifest?.services?.onboarding?.userOnboardingPath;
  const contentPath = content?.path;

  const isUserOnboardingPath = useMemo(() => {
    if (userOnboardingPath) {
      const contentPathArray = Array.isArray(contentPath)
        ? contentPath
        : [contentPath];

      return contentPathArray.some((path) => path === userOnboardingPath);
    }

    return false;
  }, [contentPath, userOnboardingPath]);

  const lazyLoadModalResumeSession = useCallback(async (): Promise<any> => {
    return import('../ModalResumeSession');
  }, []);
  const loadDefaultOnboardingError = content?.loadDefaultOnboardingError;

  useEffect(() => {
    if (loadDefaultOnboardingError) {
      applicationStatusManager?.showErrorPage?.({
        message: {
          label:
            'Please click on retry, if it still not work you may need to restart it'
        },
        buttons: {
          list: [
            {
              label: 'Retry',
              onClick: async () => {
                await content?.rootProperties?.retryUpdateOnboardingSession?.();
              }
            },
            {
              label: 'Exit Setup',
              onClick: async () => {
                await content?.rootProperties?.closeOnboardingSession?.();
              }
            }
          ]
        }
      });

      return () => applicationStatusManager?.hideAll?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDefaultOnboardingError]);

  if (loadDefaultOnboardingError) {
    return null;
  }

  return (
    <ConditionalTenantPageLoader
      public={!!content?.public}
      isUserOnboardingPath={isUserOnboardingPath}
      isPathRoute={!!content?.isPathRoute}
      {...props}
    >
      <>
        {modalContent?.showResumeSessionModal ? (
          <LazyMfeLoader
            {...props}
            rootProperties={modalContent?.rootProperties}
            key={modalContent?.key}
            // progressIndicator="linear"
            // assetReference={modalContent?.assetReference}
            properties={modalContent?.properties}
            assetImportCallback={lazyLoadModalResumeSession}
          />
        ) : (
          <Modal
            show={!!modalContent?.enable}
            align="center"
            // closeButton
            // onClose={() => fallback?.clearFallbackState?.()}
          >
            <div
              style={{ maxHeight: '75vh', height: '75vh', overflow: 'auto' }}
            >
              {modalContent?.enable && (
                <LazyMfeLoader
                  {...props}
                  rootProperties={modalContent?.rootProperties}
                  key={modalContent?.key}
                  progressIndicator="linear"
                  assetReference={modalContent?.assetReference}
                  properties={modalContent?.properties}
                />
              )}
            </div>
          </Modal>
        )}

        <LayoutHandler
          enable={!!layout?.enable}
          key={layout?.key}
          progressIndicator="linear"
          assetReference={layout?.assetReference}
          properties={layout?.properties}
          localizationResources={localizationResources}
          useDefaultRouteLayout={layout?.useDefaultRouteLayout as boolean}
          useDefaultOnboardingLayout={
            layout?.useDefaultOnboardingLayout as boolean
          }
          content={content}
          stack={stack}
        >
          {(injectedChildrenProperties) => {
            return (
              content?.enable && (
                <LazyMfeLoader
                  {...props}
                  rootProperties={{
                    ...content?.rootProperties,
                    ...injectedChildrenProperties
                  }}
                  key={content?.key}
                  progressIndicator="linear"
                  assetReference={content?.assetReference}
                  properties={content?.properties}
                />
              )
            );
          }}
        </LayoutHandler>
      </>
    </ConditionalTenantPageLoader>
  );
};

function ConditionalTenantPageLoader({
  public: isPublic,
  isPathRoute,
  children,
  isUserOnboardingPath,
  ...tenantPageLoaderProps
}: TenantPageLoaderPropsType & {
  public?: boolean;
  children?: any;
  isPathRoute?: boolean;
  isUserOnboardingPath?: boolean;
}) {
  const { tenantHandler } = useRootContext();

  const lazyLoadTenantSelector = useCallback(async (): Promise<any> => {
    return import('../TenantPageLoader');
  }, []);

  const useTenantPageLoader = useMemo(() => {
    return (
      tenantHandler?.isEnabled() &&
      isPathRoute &&
      !isPublic &&
      !isUserOnboardingPath
    );
  }, [isPathRoute, isPublic, isUserOnboardingPath, tenantHandler]);

  if (useTenantPageLoader) {
    return (
      <LazyMfeLoader
        {...tenantPageLoaderProps}
        assetImportCallback={lazyLoadTenantSelector}
      >
        {children}
      </LazyMfeLoader>
    );
  }

  return children;
}

function LayoutHandler({
  enable,
  children,
  useDefaultOnboardingLayout,
  useDefaultRouteLayout,
  content,
  ...props
}: LazyMfeLoaderPropsType & {
  children: (props: Record<string, any>) => any;
  enable: boolean;
  useDefaultRouteLayout?: boolean;
  useDefaultOnboardingLayout?: boolean;
  content?: MicrofrontendRouterAssetType;
}) {
  const lazyLoadHeaderAndFooter = useCallback(async (): Promise<any> => {
    return import('../../layouts/HeaderAndFooter');
  }, []);

  const lazyLoadSystemLayout = useCallback(async (): Promise<any> => {
    return import('../../layouts/DefaultSystem/components/SystemLayout');
  }, []);

  if (useDefaultOnboardingLayout) {
    return (
      <LazyMfeLoader
        content={content}
        assetImportCallback={lazyLoadHeaderAndFooter}
      />
    );
  } else if (useDefaultRouteLayout) {
    return (
      <LazyMfeLoader
        currentPath={content}
        assetImportCallback={lazyLoadSystemLayout}
      >
        {children}
      </LazyMfeLoader>
    );
  } else if (enable && props?.assetReference) {
    return <LazyMfeLoader {...props}>{children}</LazyMfeLoader>;
  }

  return <>{typeof children === 'function' ? children({}) : children}</>;
}

export default Renderer;
