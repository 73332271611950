import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import React, { useEffect, useState } from 'react';
import { useRootContext } from '../../contexts/Root';
import MfeNotFound from '../MfeNotFound';
import useAssetImport from './hooks/useAssetImport';
import * as T from './types';
import * as S from './styles';

const LazyMfeLoader: React.FC<T.LazyMfeLoaderPropsType> = ({
  children,
  progressIndicator,
  rootProperties,
  showLoader,
  onMount,
  ...props
}) => {
  const { componentProps, isLoading, mfe } = useAssetImport(props);
  const { store, session, stack } = useRootContext();
  const [mfeKey, setMfeKey] = useState(0);
  const styledPosition = componentProps?.styledPosition || 'relative';

  useEffect(() => {
    onMount?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const observerId = store.generateId();
    store.addObserver(observerId, (state, prevState) => {
      if (
        state?.manifest !== prevState?.manifest &&
        JSON.stringify(state?.manifest) !== JSON.stringify(prevState?.manifest)
      ) {
        setMfeKey(mfeKey + 1);
      }
    });
    return () => store.removeObserver(observerId);
  }, [store, mfeKey]);

  if (isLoading || showLoader) {
    return (
      <S.LoadingContainer style={{ position: `${styledPosition}` }}>
        <ProgressIndicator appearance={progressIndicator} />
      </S.LoadingContainer>
    );
  } else if (mfe?.default) {
    return (
      <mfe.default
        {...{
          ...rootProperties,
          session,
          stack,
          ...componentProps,
          children
        }}
        key={mfeKey}
      />
    );
  } else if (children) {
    return <>{children === 'function' ? (children as any)({}) : children}</>;
  } else {
    return <MfeNotFound />;
  }
};

export default LazyMfeLoader;
