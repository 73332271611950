import { createContext } from 'react';
import { MfePropsType } from '../../types/mfeProps';

export type RootContext = {
  id: string;
  children?: any;
} & MfePropsType;

const RootContext = createContext(
  {} as RootContext & {
    localizationResources: Record<string, any>;
  }
);

export default RootContext;
