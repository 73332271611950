export function getCropedResourceId(nodeId: string) {
  return `(${cropText({
    data: nodeId,
    limit: 4,
    ellipsisLocation: 'start'
  })})`;
}

export function getCropedAccountName(accountName: string) {
  return cropText({
    data: accountName,
    limit: 20,
    ellipsisLocation: 'middle'
  });
}

export function cropText(options: {
  data: string;
  limit: number;
  ellipsisLocation: 'start' | 'middle' | 'end';
}) {
  const { data = '', limit = 4, ellipsisLocation = 'end' } = options || {};
  const nodeIdLenght = data.length || 0;

  if (nodeIdLenght > limit) {
    const startLimit = Math.ceil(limit / 2);
    const endLimit = Math.floor(limit / 2);

    switch (ellipsisLocation) {
      case 'start':
        return `...${data?.substr(nodeIdLenght - limit)}`;
      case 'middle':
        return `${data?.substr(0, startLimit)}...${data?.substr(
          nodeIdLenght - endLimit
        )}`;
      case 'end':
        return `${data?.substr(0, limit)}...`;
      default:
        return data;
    }
  }
  return data;
}
