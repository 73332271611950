import React, { useMemo, useEffect, useState } from 'react';
import RootContext, { RootContext as RootContextType } from './RootContext';
import localizationResources from '../../assets/locale';
import { ManifestType } from '../../types/manifest';
const RootProvider = ({ children, ...props }: RootContextType) => {
  const { localization, store } = props;

  const [manifest, setManifest] = useState<ManifestType>(
    store?.state?.manifest
  );

  const TranslatorProvider = useMemo(
    () => localization.getReactTranslatorProvider(React),
    [localization]
  );

  useEffect(() => {
    const observerId = store.generateId();
    store.addObserver(observerId, (state) => setManifest(state?.manifest));
    return () => store.removeObserver(observerId);
  }, [store]);

  const mosaicProps = manifest;

  return (
    <RootContext.Provider
      value={{ ...props, mosaicProps, localizationResources }}
    >
      <TranslatorProvider resources={localizationResources}>
        {children}
      </TranslatorProvider>
    </RootContext.Provider>
  );
};

export default RootProvider;
