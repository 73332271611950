const packageJson = require('../../package.json');

const [orgName, projectName] = packageJson.name.substring(1).split(/\/(.+)/);

const projectNames = {
  orgName,
  projectName,
  namespace: `${orgName}__${projectName}`,
  packageJsonName: packageJson.name
};

exports.orgName = projectNames.orgName;
exports.projectName = projectNames.projectName;
exports.namespace = projectNames.namespace;
exports.packageJsonName = projectNames.packageJsonName;

module.exports = projectNames;
