import React from 'react';
import * as S from './styles';
import BatteryChargingIcon from '@veneer/core/dist/scripts/icons/icon_battery_charging';
import { useRootContext } from '../../contexts/Root';
import createId from '../../utils/createId';

const MfeNotFound = (props) => {
  const { localization } = useRootContext();
  const { t } = localization.useReactTranslatorHook();

  return (
    <S.Container
      data-testid={createId('mfe_not_found')}
      {...props}
    >
      <BatteryChargingIcon />
      {t('nav.not-found.content', 'This content is under development...')}
    </S.Container>
  );
};

export default MfeNotFound;
