import styled from 'styled-components';
import Card from '@veneer/core/dist/scripts/card';
import tokens from '@veneer/tokens/dist';
import { isIE } from '../../utils/ie';

type CustomType = {
  isselect?: boolean;
};

export const Background = styled.div`
  background: ${tokens.colorGray2};
  align-self: center;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const Container = styled(Card)`
  width: 480px;
`;

export const Content = styled.div<CustomType>`
  padding: ${({ isselect }) => (isselect ? '58.5px' : '48px')};
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormDiv = styled.div<CustomType>`
  display: flex;
  flex-direction: column;
  ${({ isselect }) => isselect && 'gap: 12px;'};
  ${() => isIE() && `margin-top: ${tokens.space6};`}
`;

export const Title = styled.h1`
  font-family: inherit;
  font-size: ${tokens.fontSize6};
  line-height: 28px;
  color: #222222;
`;

export const SubTitle = styled.h2`
  font-family: inherit;
  font-size: ${tokens.fontSize3};
  line-height: 19px;
  color: #404040;
  ${() => isIE() && `margin-top: ${tokens.space2};`}
`;
