import Select from '@veneer/core/dist/scripts/select';
import React, { useEffect, useMemo, useState } from 'react';
import { getCropedAccountName, getCropedResourceId } from './utils/cropText';
import * as S from './styles';
import * as T from './types';
import Button from '@veneer/core/dist/scripts/button';
import RadioButton from '@veneer/core/dist/scripts/radio_button';
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator';
import RadioButtons from '@veneer/core/dist/scripts/radio_buttons';
import { useRootContext } from '../../contexts/Root';
import createId from '../../utils/createId';

const domainsRadioLimit = 5;

const DomainSelectorPage: T.DomainSelectorPageType = () => {
  const { localization, orgSelector } = useRootContext();
  const [isChangingDomain, setIsChangingDomain] = useState(false);
  const [selectedDomainId, setSelectedDomainId] = useState(
    orgSelector?.getCurrentTenant()?.nodeId
  );
  const domains = useMemo(
    () =>
      orgSelector
        ?.getTenantList?.()
        ?.map(({ accountName, description, nodeId }) => {
          const label = `${getCropedAccountName(
            accountName || description
          )} ${getCropedResourceId(nodeId)}`;
          return {
            label,
            value: nodeId
          };
        }) || [],
    [orgSelector]
  );

  useEffect(() => {
    if (domains?.length === 1) {
      (async () => {
        await orgSelector.setTenantCookie(domains?.[0]?.value, {
          refreshToken: true,
          reloadPageOnCookieChange: true
        });
        window.location.reload();
      })();
    }
    setSelectedDomainId(domains[0]?.value);
  }, [domains, orgSelector]);

  const isSelect = (domains?.length || 0) > domainsRadioLimit;
  const { t } = localization.useReactTranslatorHook();
  const firstItem = domains.length ? domains[0]?.value : undefined;

  async function onConfirmation() {
    if (selectedDomainId) {
      setIsChangingDomain(true);
      try {
        await orgSelector.setTenantCookie(selectedDomainId, {
          refreshToken: true,
          reloadPageOnCookieChange: true
        });
        await new Promise(() => {
          window.location.reload();
        });
        setIsChangingDomain(false);
      } catch {
        setTimeout(() => {
          onConfirmation();
        }, 1000);
      }
    }
  }

  if (domains?.length <= 1) {
    return null;
  }

  return (
    <S.Background>
      <S.Container
        data-testid={createId('container')}
        appearance="dropShadow"
        content={
          <S.Content isselect={isSelect}>
            <S.TitleWrapper>
              <S.Title data-testid={createId('welcome_back')}>
                {t('nav.domain-selector.page.title', 'Welcome Back')}
              </S.Title>
              <S.SubTitle>
                {t(
                  'nav.domain-selector.page.subtitle',
                  'Select an organization to sign in.'
                )}
              </S.SubTitle>
            </S.TitleWrapper>
            <S.FormDiv isselect={isSelect}>
              {isSelect ? (
                <Select
                  disabled={isChangingDomain}
                  options={domains}
                  clearIcon={false}
                  value={selectedDomainId ? [selectedDomainId] : undefined}
                  onChange={(e) => setSelectedDomainId(e.value as string)}
                />
              ) : (
                <RadioButtons
                  name="radiobutton_default"
                  defaultValue={firstItem}
                  value={selectedDomainId}
                  onChange={(e, value) => setSelectedDomainId(value)}
                  data-testid={createId('organizationList')}
                >
                  {domains?.map?.((props, index) => (
                    <RadioButton
                      key={props.value}
                      disabled={isChangingDomain}
                      data-testid={createId('organization_' + index)}
                      {...props}
                    />
                  ))}
                </RadioButtons>
              )}
              <Button
                disabled={!selectedDomainId || isChangingDomain}
                onClick={() => onConfirmation()}
                appearance="primary"
                data-testid={createId('continue_button')}
              >
                {isChangingDomain ? (
                  <ProgressIndicator
                    color="colorGray4"
                    appearance="circular"
                  />
                ) : (
                  t('nav.domain-selector.page.button', 'Continue')
                )}
              </Button>
            </S.FormDiv>
          </S.Content>
        }
      />
    </S.Background>
  );
};

export default DomainSelectorPage;
